import React from "react";
import snowman from "../snowman.jpg";

const Footer = () => {
  return (
    <div className="ImgFoot">
      <img
        src={snowman}
        alt="Muñeco de nieve en la noche de los Tres Reyes Magos de Oriente"
      />
      ;
    </div>
  );
};

export default Footer;
