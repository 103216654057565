import React from "react";
const Logo = () => {
  return (
    <div className="container-top-logo">
      <div className="container-logo">
        <h2 className="title-logo">
          CartaReyes<span>.ES</span>
        </h2>
      </div>
    </div>
  );
};
export default Logo;
