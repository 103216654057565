import LosTRM from "../LosTRM.svg";

const ImgHeader = () => {
  return (
    <div className="ImgHeader">
      <img src={LosTRM} alt="Los Tres Reyes Magos" />;
    </div>
  );
};

export default ImgHeader;
