import Regalos from "../regalos.jpg";

const ImgFormMed = () => {
  return (
    <div className="ImgFormMed">
      <img src={Regalos} alt="Los regalos de los Tres Reyes Magos" />;
    </div>
  );
};

export default ImgFormMed;
