import tresCamellos from "../tresCamellos.jpg";

const ImgForm = () => {
  return (
    <div className="ImgForm">
      <img src={tresCamellos} alt="Los Camellos de los Tres Reyes Magos" />;
    </div>
  );
};

export default ImgForm;
