import React from "react";
import Logo from "./Logo";
import Timer from "./Timer";
import ImgHeader from "./ImgHeader";

const Header = () => {
  return (
    <div className="Container-Header">
      <div>
        <Logo />
      </div>
      <div className="imgHeader">
        <ImgHeader />
      </div>
      <div>
        <Timer />
      </div>
    </div>
  );
};

export default Header;
