import CookieConsent from "react-cookie-consent";

import "../PopupCookies.css";

const PopupCookies = () => {
  return (
    <div className="popupCookies">
      <CookieConsent
        disableStyles
        location="none"
        buttonText="ACEPTAR"
        cookieName="CartaReyes.ES"
        overlay
        overlayClasses="overlayclass"
      >
        Este sitio web es un proyecto en modo de prueba. Al hacer clic en el
        botón "ACEPTAR", usted comprende esto y nos exime de cualquier
        responsabilidad. Si no está de acuerdo, abandone la web ahora.
        <p className="msg2">
          Recopilamos información personal de usted, incluida información sobre
          su: nombre información de contacto Recopilamos su información personal
          para: recibirá un correo electrónico con la carta que el niño le
          escribió a Santa Además de nuestro personal, compartimos esta
          información con: formspree. io para procesar los datos de nuestro
          formulario y enviarle un correo electrónico. Proporcionar información
          es opcional. Si elige no ingresar su correo electrónico, no podremos
          enviarle un correo electrónico con la carta que el niño le escribió a
          Santa. Mantenemos su información segura almacenándola en los archivos
          de formspree.io. Conservamos su información durante 90 días, momento
          en el que la destruimos de forma segura. Tiene derecho a solicitar una
          copia de cualquier información personal que tengamos sobre usted y a
          solicitar que se corrija si cree que es incorrecta. Si desea solicitar
          una copia de su información o que la corrijan, comuníquese con
          nosotros en hola@CartaReyes.ES
        </p>
      </CookieConsent>
    </div>
  );
};

export default PopupCookies;
