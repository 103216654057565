import { useForm } from "../hooks/useForm";
import ImgForm from "./ImgForm";
// import ImgFormFoot from "./ImgFormFoot";
import ImgFormMed from "./ImgFormMed";
import Loader from "./Loader";
import Message from "./Message";

const initialForm = {
  name: "",
  edad: "",
  pais: "",
  email: "",
  behave: "",
  comments: "",
};
const validationForm = (form) => {
  let errors = {};
  let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
  let regexEdad = /^[0-9]+[0-9]*$/;
  let regexpais = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
  let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
  let regexBehave = /^.{10,2000}$/;
  let regexComments = /^.{10,2000}$/;

  if (!form.name.trim()) {
    errors.name = "Necesitamos saber tu nombre";
  } else if (!regexName.test(form.name.trim())) {
    errors.name = "Solo puedes escribir letras para tu 'nombre'";
  }
  if (!form.edad.trim()) {
    errors.edad = "Escribe tu edad";
  } else if (!regexEdad.test(form.edad.trim())) {
    errors.edad = "Tu edad es un número igual o mayor a cero";
  }

  if (!form.pais.trim()) {
    errors.pais = "¿En qué localidad dormirás la noche de Reyes?";
  } else if (!regexpais.test(form.pais.trim())) {
    errors.pais = "Tu ciudad o pueblo se escribe con letras";
  }
  if (!form.email.trim()) {
    errors.email = "Necesitamos un correo de contacto por si tenemos preguntas";
  } else if (!regexEmail.test(form.email.trim())) {
    errors.email = "El email debe tener el símbolo '@'";
  }
  if (!form.behave.trim()) {
    errors.behave = "¿Cómo te has portado este año?";
  } else if (!regexBehave.test(form.behave.trim())) {
    errors.behave =
      "Explica cómo te has portado en casa y en el colegio. Tienes un mínimo de 200 caracteres y un límite de 2000 caracteres";
  }
  if (!form.comments.trim()) {
    errors.comments = "¿Qué te gustaría que te trajeran los Reyes Magos?";
  } else if (!regexComments.test(form.comments.trim())) {
    errors.comments = "Tu lista de deseos tiene un límite de 2000 caracteres";
  }

  return errors;
};

const ContactForm = () => {
  const {
    form,
    errors,
    loading,
    response,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useForm(initialForm, validationForm);

  return (
    <div className="FormContainer">
      <h1 className="titleForm">Cuéntanos quién eres tú</h1>
      <div>
        <form className="form-elements" onSubmit={handleSubmit}>
          <div>
            <label className="question">¿Cómo te llamas?</label>
            {errors.name && <p className="error">{errors.name}</p>}
            <br />
            <input
              type="text"
              name="name"
              placeholder="Tu nombre"
              onBlur={handleBlur}
              onChange={handleChange}
              value={form.name}
              required
            />
          </div>

          <div>
            <label className="question">¿Cuántos años tienes?</label>
            {errors.edad && <p className="error">{errors.edad}</p>}
            <br />
            <input
              type="number"
              name="edad"
              placeholder="Tu edad"
              onBlur={handleBlur}
              onChange={handleChange}
              value={form.edad}
              required
            />
          </div>

          <div>
            <label className="question">
              ¿Dónde estarás en la noche de Reyes?
            </label>
            {errors.pais && <p className="error">{errors.pais}</p>}
            <br />
            <input
              type="text"
              name="pais"
              placeholder="Localidad"
              onBlur={handleBlur}
              onChange={handleChange}
              value={form.pais}
              required
            />
          </div>
          <div>
            <label className="question">¿Cuál es tu email?</label>
            {errors.email && <p className="error">{errors.email}</p>}
            <br />
            <input
              type="email"
              name="email"
              placeholder="Email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={form.email}
              required
            />
          </div>

          <div>
            <ImgFormMed />
          </div>
          <div>
            <h2 className="titleForm cuentanos">Cuéntanos más sobre ti</h2>
          </div>
          <div>
            <label className="question">¿Cómo te has portado este año?</label>
            {errors.behave && <p className="error">{errors.behave}</p>}
            <br />
            <textarea
              name="behave"
              cols="50"
              rows="10"
              placeholder="Este año me he portado..."
              onBlur={handleBlur}
              onChange={handleChange}
              value={form.behave}
              required
            ></textarea>
          </div>

          <div>
            <ImgForm />
          </div>
          <div>
            <h2 className="titleForm">Cuéntanos tus deseos</h2>
          </div>
          <div>
            <label className="question">
              ¿Qué quieres que te traigan Noche de Reyes Magos?
            </label>
            {errors.comments && <p className="error">{errors.comments}</p>}
            <br />
            <textarea
              name="comments"
              cols="50"
              rows="20"
              placeholder="Queridos Reyes Magos..."
              onBlur={handleBlur}
              onChange={handleChange}
              value={form.comments}
              required
            ></textarea>
          </div>

          <div className="btn">
            <input
              className="button"
              type="submit"
              value="Enviar Carta Reyes"
              required
            />
          </div>
        </form>
      </div>
      {loading && <Loader />}
      {response && (
        <Message
          msg="Tu Carta a los Reyes Magos se ha enviado"
          bgColor="#green"
        />
      )}
    </div>
  );
};

export default ContactForm;
